<template>
  <section class="portal">
    <div class="grid grid-pad">
      <div class="col-1-1">
        <h1 class="page-title">{{ $t("Welcome to BL Care") }}</h1>
      </div>

      <div class="portal-choices flex col-1-1 no-p">
        <div class="col-1-2 xs-col-1-1 flex">
          <div class="choice-item flex flex-column justify-space-between bg-blue">
            <div class="text">
              <h5 class="subtitle">{{ $t("Having a problem? We can help!") }}</h5>
              <h3 class="title">{{ $t("Create  a  ticket") }}</h3>
            </div>
            <div>
            <router-link :to="{name: 'Retailer-CreateTicket'}" class="btn btn-secondary">{{ $t("Create a ticket") }}</router-link>
            </div>
          </div>
        </div>

        <div class="col-1-2 xs-col-1-1 flex">
          <div class="choice-item flex flex-column justify-space-between bg-blue">
            <div class="text">
              <h5 class="subtitle">{{ $t("Want an update on your ticket?") }}</h5>
              <h3 class="title">{{ $t("Request ticket status") }}</h3>
            </div>
            <div>
            <router-link :to="{name: 'Retailer-RequestStatus'}" class="btn btn-secondary">{{ $t("Request status") }}</router-link>
            </div>
          </div>
        </div>

        <!-- <div class="col-1-3 sm-col-1-2 xs-col-1-1 flex">
          <div class="choice-item flex flex-column justify-space-between bg-blue">
            <div class="text">
              <h5 class="subtitle">{{ $t("We might already have an answer!") }}</h5>
              <h3 class="title">{{ $t("Frequently asked questions") }}</h3>
            </div>
            <div>
            <a href="https://brandloyalty-customersupport.freshdesk.com/en/support/solutions" target="_blank" class="btn btn-secondary">{{ $t("View Q&A") }}</a>
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Retailer-Portal',
  components: {},
  data () {
    return {}
  },
  beforeCreate () {
    this.$store.commit('setSettingRetailer', true)
  },
  mounted () {
    // set country and retailer
    if (this.$route.query.country) {
      this.country = parseInt(this.$route.query.country)
      sessionStorage.setItem('retailer_country', this.$route.query.country)

      if (this.$route.query.retailer) {
        this.retailer = parseInt(this.$route.query.retailer)
        sessionStorage.setItem('retailer_retailer', this.$route.query.retailer)
      }
    } else if (sessionStorage.getItem('retailer_country')) {
      this.country = parseInt(sessionStorage.getItem('retailer_country'))
      if (sessionStorage.getItem('retailer_retailer')) {
        this.retailer = parseInt(sessionStorage.getItem('retailer_retailer'))
      }
    }

    // set color
    if (this.$route.query.color) {
      document.documentElement.style.setProperty('--portal-color', decodeURI(this.$route.query.color))
      sessionStorage.setItem('retailer_color', decodeURI(this.$route.query.color))
    } else if (sessionStorage.getItem('retailer_color')) {
      document.documentElement.style.setProperty('--portal-color', sessionStorage.getItem('retailer_color'))
    }
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>
